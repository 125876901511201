import "core-js/modules/es.array.push.js";
import { timeToTimestamp, changeRateColor, transposeArray, getEmptyList } from '../../../../../utils/index.js';
import StatisticsTable from '../statistics-table.vue';
export default {
  components: {
    StatisticsTable
  },
  props: {
    list: {
      type: Array,
      default: null
    },
    form: {
      type: Object,
      default: null
    },
    empty: {
      type: Array,
      default: null
    },
    dimension: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      originData: this.list,
      originTitle: this.$global.dailyRate,
      logisticSupplierVisible: false,
      table: {},
      emptyStr: '',
      chooseForm: this.form,
      lastLabel: [],
      title: ''
    };
  },
  watch: {
    list(val) {
      this.originData = val;
      this.handleArray();
    },
    form(val) {
      this.chooseForm = val;
    }
  },
  methods: {
    changeRateColor,
    //数组转置
    handleArray() {
      this.originTitle.push(this.$global.titleMap[this.dimension]);
      for (const index in this.originData) {
        this.originData[index].data = transposeArray(this.originData[index].data, this.originTitle);
      }
      this.emptyStr = getEmptyList(this.empty);
      this.originTitle.pop();
    },
    async viewLogisticSupplier(id, val, item) {
      if (parseFloat(val) && val !== '-') {
        let params = JSON.parse(JSON.stringify(this.chooseForm));
        params.shipping_time = timeToTimestamp(item);
        params.statistical_dimension_id = id;
        delete params.end_shipping_time;
        delete params.start_shipping_time;
        try {
          await this.$store.dispatch('statistics/getLogisticSupplier', {
            params
          });
          this.logisticSupplier = this.$store.state.statistics.logisticSupplier;
          this.lastLabel = this.$global.titleMap[this.dimension];
          this.table = {
            tableData: this.logisticSupplier,
            tableFields: [{
              label: `${this.lastLabel}名称`,
              prop: 'name'
            }].concat(this.$global.commonListFields)
          };
          this.title = this.lastLabel.substring(0, this.lastLabel.length - 2);
          this.logisticSupplierVisible = true;
        } catch (err) {
          return;
        }
      } else {
        this.$message.warning('当前无数据可查看');
      }
    }
  }
};