import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_daily_table = _resolveComponent("daily-table");
  const _component_common_statistics = _resolveComponent("common-statistics");
  return _openBlock(), _createElementBlock("section", null, [_createVNode(_component_common_statistics, {
    properties: _ctx.$global.dailyChooseOptions,
    type: "daily",
    visible: _ctx.$store.state.statistics.dailyTableVisible,
    onGetForm: $options.getForm
  }, {
    default: _withCtx(() => [_createVNode(_component_daily_table, {
      form: $data.form,
      list: $data.dailyStatistics,
      empty: $data.emptyList,
      dimension: $data.dimension
    }, null, 8, ["form", "list", "empty", "dimension"])]),
    _: 1
  }, 8, ["properties", "visible", "onGetForm"])]);
}