import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, normalizeStyle as _normalizeStyle, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-971e3b5e"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 0,
  style: {
    "color": "#ea1d1d",
    "margin": "10px"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_table_column = _resolveComponent("el-table-column");
  const _component_el_table = _resolveComponent("el-table");
  const _component_el_scrollbar = _resolveComponent("el-scrollbar");
  const _component_statistics_table = _resolveComponent("statistics-table");
  const _component_base_option = _resolveComponent("base-option");
  const _directive_loading = _resolveDirective("loading");
  return _withDirectives((_openBlock(), _createElementBlock("section", null, [$props.empty.length ? (_openBlock(), _createElementBlock("div", _hoisted_1, " 提示：" + _toDisplayString($data.emptyStr), 1)) : _createCommentVNode("", true), _createVNode(_component_el_scrollbar, {
    height: $data.originData.length ? '600px' : ''
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.originData, (data, i) => {
      return _openBlock(), _createElementBlock("div", {
        key: i,
        style: {
          "margin": "20px 0"
        }
      }, [_createElementVNode("div", null, _toDisplayString(data.name), 1), _createVNode(_component_el_table, {
        border: "",
        style: {
          "margin-top": "20px",
          "width": "99%"
        },
        data: data.data,
        "show-header": false
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.data[0].length, (item, index) => {
          return _openBlock(), _createBlock(_component_el_table_column, {
            key: index,
            "class-name": index === 0 ? 'column-header' : '',
            align: "center",
            "min-width": "150px"
          }, {
            default: _withCtx(({
              row
            }) => [row[0].indexOf('数量') !== -1 && index !== 0 ? (_openBlock(), _createBlock(_component_el_button, {
              key: 0,
              text: "",
              type: "primary",
              onClick: $event => $options.viewLogisticSupplier(data.id, row[index], data.data[0][index])
            }, {
              default: _withCtx(() => [_createTextVNode(_toDisplayString(row[index]), 1)]),
              _: 2
            }, 1032, ["onClick"])) : (_openBlock(), _createElementBlock("span", {
              key: 1,
              style: _normalizeStyle(row[index].indexOf('%') > -1 ? {
                color: $options.changeRateColor(row[index])
              } : {})
            }, _toDisplayString(row[index]), 5))]),
            _: 2
          }, 1032, ["class-name"]);
        }), 128))]),
        _: 2
      }, 1032, ["data"])]);
    }), 128))]),
    _: 1
  }, 8, ["height"]), _createVNode(_component_base_option, {
    modelValue: $data.logisticSupplierVisible,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.logisticSupplierVisible = $event),
    title: `${$data.title}每日时效统计`
  }, {
    default: _withCtx(() => [_createVNode(_component_statistics_table, {
      table: $data.table
    }, null, 8, ["table"])]),
    _: 1
  }, 8, ["modelValue", "title"])])), [[_directive_loading, _ctx.$store.state.statistics.dailyLoading]]);
}